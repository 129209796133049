import notification from '../utilitarios/notification.js'

const url = 'index.php?route=account/wishlist';
const wishlist = {
	'add': function(product_id) {
		$.ajax({
			url: `${url}/add`,
			type: 'post',
			data: `product_id=${product_id}`,
			dataType: 'json',
			success: function(json) {

				if (json['redirect']) {
					location = json['redirect'];
				}

				// if (json['success']) {
				// 	notification.success(json['success']);
				// }

				// $('#notificacao').append(
				// 	'<div class="alerta__custom alerta-success alert">\n' +
				// 	'        <button class="close" type="button" data-dismiss="alert">&times;</button>\n' +
				// 	'        <p>'+ json['success'] +'</p>\n' +
				// 	'</div>'
				// );

				// window.scrollTo({
				// 	top: 0,
				// 	behavior: 'smooth'
				// });
			if (json["success"]) {
				Swal.fire("Sucesso", "" + json["success"] + "", "success");
			}

			if (json["warning"]) {
				Swal.fire("Obs..", "" + json["warning"] + "", "warning");
			}

			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
	},
	'remove': function() {

	}
}

window.wishlist = wishlist;